@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add custom CSS here */
@media (orientation: landscape) {
    .landscape-warning {
        @apply block;
        /* Show warning or adjust layout */
    }

    .main-content {
        @apply hidden;
        /* Hide the main content or adjust */
    }
}

@media (orientation: portrait) {
    .landscape-warning {
        @apply hidden;
    }

    .main-content {
        @apply block;
    }
}

@layer utilities {
    .old-price::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: red;
        /* Use any color */
        transform: rotate(-20deg);
        /* Adjust the angle */
        transform-origin: left center;
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}